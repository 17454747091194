/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 4657502 */
  src: url('//at.alicdn.com/t/c/font_4657502_ydjnqbg0jyb.woff2?t=1724040939628') format('woff2'),
       url('//at.alicdn.com/t/c/font_4657502_ydjnqbg0jyb.woff?t=1724040939628') format('woff'),
       url('//at.alicdn.com/t/c/font_4657502_ydjnqbg0jyb.ttf?t=1724040939628') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 1.4vw;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back:before {
  content: "\e617";
}

.home-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px; /* 两部分之间的间距 */
  margin: 20px auto;
  max-width: 1200px; /* 容器的最大宽度 */
}

.home-logo {
  display: block; /* 使图片显示为块级元素 */
  max-width: 300px; /* 图片最大宽度 */
  height: auto; /* 保持图片宽高比 */
  margin: 0 auto 20px; /* 居中并设置底部边距 */
  /* 你可以根据需要调整以上样式 */
}

.home-container h1 {
  text-align: center;
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
}

.left-section, .right-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px; /* 正方形高度 */
  width: 300px; /* 正方形宽度 */
  margin: 10px;
  border: 1px solid #555; /* 边框 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 轻微的阴影 */
  background-color: #333;
  color: #FFF;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out; /* 平滑过渡效果 */
  cursor: pointer;
  text-decoration: none;
}

.left-section:hover, .right-section:hover {
  /* background-color: #f8f8f8; */
  /* color: #333; */
  transform: scale(1.05); /* 鼠标悬停时放大 */
}

.left-section h2, .right-section h2 {
  font-size: 24px; /* 标题字体大小 */
}

.questions-page {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
}

.section {
  margin: 40px 0;
}
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-bottom: 2px solid #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.section-title {
  font-size: 40px;
  color: #333;
  display: flex;
  align-items: end;
}
.section-back {
  font-size: 1.4vw;
  cursor: pointer;
  color: #666;
}
.section-logo {
  height: 60px;
  width: 60px;
  cursor: pointer;
}
.questions {
  margin: 20px 0;
}
.question {
  background: #ddd;
  margin-bottom: 10px;
  padding: 10px;
  cursor: help;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  display: block;
  text-decoration: none;
  color: #323232;
  font-size: 36px;
}

.question:hover {
  transform: scale(1.05);
}

.question-detail-page {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
}
/* 面包屑样式 */
.breadcrumbs {
  font-size: 24px;
  color: #666;
  padding: 10px 0;
}

.breadcrumbs a {
  /* color: #007bff; */
  color: #323232;
  text-decoration: none;
  margin: 0 5px;
}

.breadcrumbs a:hover {
  text-decoration: underline;
}

.breadcrumbs span {
  margin: 0 5px;
}

/* Q&A 详情页样式 */
.question-detail-page {
  padding: 20px;
  background: #fff;
  margin-top: 20px; /* 与面包屑保持一定间距 */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-title {
  font-size: 40px;
  color: #333;
  margin-bottom: 10px;
}

.answer-content {
  font-size: 16px;
  line-height: 1.6;
  margin-top: 15px;
  color: #666;
  padding: 10px;
  background-color: #f9f9f9;
  /* border-left: 3px solid #007bff; */
}

/* 当没有找到问题时的提示样式 */
.not-found {
  color: #d9534f;
  font-size: 18px;
  margin-top: 20px;
}

.question-detail-header {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}